import { AnimatePresence, motion } from "framer-motion";
import { Moon, Sun } from "lucide-react";
import { signalPersist } from "@repo/share";
import { effect } from "@preact/signals-react";
import "@preact/signals-react/auto";

const theme = signalPersist("theme", "light");

export function ThemeToggle() {
  const toggleTheme = () => {
    theme.value = theme.value === "light" ? "dark" : "light";
  };

  effect(() => {
    if (!theme.value) return;
    const root = document.documentElement;
    if (theme.value === "light") {
      root.classList.remove("dark");
    } else if (theme.value === "dark") {
      root.classList.add("dark");
    }
  });

  return (
    <button
      role="button"
      onClick={toggleTheme}
      className="min-h-[40px] block focus:outline-none"
    >
      <span className="sr-only">Toggle mode</span>
      <AnimatePresence initial={false}>
        {theme.value !== "dark" ? <SunIcon /> : <MoonIcon />}
      </AnimatePresence>
    </button>
  );
}

const SunIcon = () => (
  <motion.div
    initial={{ scale: 0.5, opacity: 0, rotate: 90 }}
    animate={{
      scale: 1,
      opacity: 1,
      rotate: 0,
      transition: { duration: 0.2, type: "spring", stiffness: 100 },
    }}
    exit={{
      scale: 0.5,
      opacity: 0,
      rotate: 90,
      transition: { duration: 0.2 },
    }}
  >
    <Sun className={"text-lg"}></Sun>
  </motion.div>
);

const MoonIcon = () => (
  <motion.div
    initial={{ scale: 0.5, opacity: 0, rotate: 90 }}
    animate={{
      scale: 1,
      opacity: 1,
      rotate: 0,
      transition: { duration: 0.2, type: "spring", stiffness: 100 },
    }}
    exit={{
      scale: 0.5,
      opacity: 0,
      rotate: 90,
      transition: { duration: 0.2 },
    }}
  >
    <Moon className={"text-lg"}></Moon>
  </motion.div>
);
